import useSWR from "swr";
import { parseCollection } from "../collections";
import { getConfig } from "common/src/config";
import communityCollections from "../community";
const config = getConfig();
export const useCollections = () => {
  return useSWR(["collections"], async () => {
    return communityCollections;
  });
};
export const useIpfsCollection = cid => {
  return useSWR(cid === undefined ? null : ["collections/ipfs", cid], async () => {
    const url = `${config.ipfs.baseUrl}/ipfs/${cid}`;
    return fetch(url).then(res => res.json()).then(parseCollection);
  });
};