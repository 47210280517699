import { useEffect, useState } from "react";
import { getAddress } from "viem";
import { useDataLayer } from "data-layer";
import { dateToEthereumTimestamp } from "common";
export const useContributionHistory = (chainIds, rawAddress) => {
  const [state, setState] = useState({
    type: "loading"
  });
  const dataLayer = useDataLayer();
  useEffect(() => {
    const fetchContributions = async () => {
      let address = "0x";
      try {
        address = getAddress(rawAddress.toLowerCase());
      } catch (e) {
        return Promise.resolve({
          chainIds,
          error: "Invalid address",
          data: []
        });
      }
      const contributions = await dataLayer.getDonationsByDonorAddress({
        address,
        chainIds
      });
      setState({
        type: "loaded",
        data: {
          chainIds: chainIds,
          data: contributions.map(contribution => ({
            ...contribution,
            timestamp: dateToEthereumTimestamp(new Date(contribution.timestamp)).toString()
          }))
        }
      });
    };
    fetchContributions();
  }, [chainIds, dataLayer, rawAddress]);
  return state;
};